import { title } from 'assets/jss/material-kit-react.js';

const productStyle = {
	section: {
		padding: '40px 0',
		textAlign: 'center'
	},
	title: {
		...title,
		marginBottom: '1rem',
		marginTop: '30px',
		minHeight: '32px',
		textDecoration: 'none'
	},
	description: {
		color: '#999'
	}
};

export default productStyle;
