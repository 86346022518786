import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faAngleLeft, faAngleRight, faPause } from '@fortawesome/free-solid-svg-icons';

const Player = ({
	currentSong,
	isPlaying,
	setIsPlaying,
	audioRef,
	setSongInfo,
	songInfo,
	songs,
	setCurrentSong,
	setSongs
}) => {
	const activeLibraryHandler = (nextPrev) => {
		const newSongs = songs.map((song) => {
			if (song.id === nextPrev.id) {
				return {
					...song,
					active: true
				};
			} else {
				return {
					...song,
					active: false
				};
			}
		});
		setSongs(newSongs);
		if (isPlaying) audioRef.current.play();
	};
	//event handlers
	const playSongHandler = () => {
		if (isPlaying) {
			audioRef.current.pause();
			setIsPlaying(!isPlaying);
		} else {
			audioRef.current.play();
			setIsPlaying(!isPlaying);
		}
	};

	const getTime = (time) => {
		return Math.floor(time / 60) + ':' + ('0' + Math.floor(time % 60)).slice(-2);
	};
	const dragHandler = (e) => {
		audioRef.current.currentTime = e.target.value;
		setSongInfo({ ...songInfo, currentTime: e.target.value });
	};
	const skipTrackHandler = async (direction) => {
		let currentIndex = songs.findIndex((song) => song.active);
		if (direction === 'skip-forward') {
			await setCurrentSong(songs[(currentIndex + 1) % songs.length]);
			activeLibraryHandler(songs[(currentIndex + 1) % songs.length]);
		}

		if (direction === 'skip-back') {
			if ((currentIndex - 1) % songs.length === -1) {
				setCurrentSong(songs[songs.length - 1]);
				activeLibraryHandler(songs[songs.length - 1]);
				if (isPlaying) audioRef.current.play();
				return;
			}
			setCurrentSong(songs[(currentIndex - 1) % songs.length]);
			activeLibraryHandler(songs[(currentIndex - 1) % songs.length]);
		}
		if (isPlaying) audioRef.current.play();
	};

	//Trying to get key press handler to work

	document.body.onkeyup = function(e) {
		// if (e.keyCode === 32) {
		// 	playSongHandler();}
		if (e.keyCode === 39) {
			skipTrackHandler('skip-forward');
		} else if (e.keyCode === 37) {
			skipTrackHandler('skip-back');
		}
	};

	// function useKey(key) {
	// 	const [ pressed, setPressed ] = useState(false);
	// 	const match = (e) => e.key.toLowerCase();
	// 	const press = (e) => {
	// 		if (match(e)) {
	// 			setPressed(true);
	// 		} else {
	// 			setPressed(false);
	// 		}
	// 	};

	// 	useEffect(
	// 		() => {
	// 			window.addEventListener('keypress', press);
	// 			return () => {
	// 				window.removeEventListener('keypress', press);
	// 			};
	// 		},
	// 		[ key ]
	// 	);
	// 	return pressed;

	// 	if (isPlaying && e.keyCode === 'Space') {
	// 		console.log('play this please');
	// 		audioRef.current.pause();
	// 		setIsPlaying(!isPlaying);
	// 	} else if (!isPlaying && e.keyCode === 'Space') {
	// 		console.log('log this please');
	// 		audioRef.current.play();
	// 		setIsPlaying(!isPlaying);
	// 	}
	// }

	// const space = useKey('space');

	//Add styles for animation
	const trackAnim = {
		transform: `translateX(${songInfo.animationPercentage}%)`
	};

	return (
		<div className="player">
			<div className="time-control">
				<p>{getTime(songInfo.currentTime)}</p>
				<div
					className="track"
					style={{
						background: `linear-gradient(to right, ${currentSong.color[0]}, ${currentSong.color[1]})`
					}}
				>
					<input
						min={0}
						max={songInfo.duration || 0}
						value={songInfo.currentTime}
						onChange={dragHandler}
						type="range"
					/>
					<div className="animate-track" style={trackAnim} />
				</div>
				<p>{songInfo.duration ? getTime(songInfo.duration) : '0:00'}</p>
			</div>
			<div className="play-control">
				<FontAwesomeIcon
					onClick={() => skipTrackHandler('skip-back')}
					className="skip-back"
					size="2x"
					icon={faAngleLeft}
				/>
				<FontAwesomeIcon
					onClick={playSongHandler}
					// useKey={playSongHandler}
					className="play"
					size="2x"
					icon={isPlaying ? faPause : faPlay}
				/>
				<FontAwesomeIcon
					onClick={() => skipTrackHandler('skip-forward')}
					className="skip-forward"
					size="2x"
					icon={faAngleRight}
				/>
			</div>
		</div>
	);
};

export default Player;
